import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createVuetify } from "vuetify";
// import store from "./store";
import { createPinia } from "pinia";
// Vuetify
import "vuetify/styles";
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import "@mdi/font/css/materialdesignicons.css";

const store = createPinia();
const vuetify = createVuetify({
    components,
    directives,

});

createApp(App).use(store).use(router).use(vuetify).mount("#app");
