import  Cookies  from 'js-cookies'

export const TOKEN_KEY = 'token'
export const  getToken = ()=> {
    return Cookies.getItem(TOKEN_KEY)
}

export const setToken = (token) => { 
    return Cookies.setItem(TOKEN_KEY , token);
}

 export const removeToken = ()=> { 
    return Cookies.removeItem(TOKEN_KEY);
}

