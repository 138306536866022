// axiosInstance.js

import axios from "axios";
import { getToken } from "@/utils/token";

// Get base URL from environment variable
const baseURL = process.env.VUE_APP_BASE_URL;

// Create axios instance with base URL
const axiosInstance = axios.create({
  baseURL: `${baseURL}/v1`,
  timeout: 10000, // Set timeout if required
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: function (status) {
    // extra codes to keep track of: 'created - 201', 'unauthorized - 401', 'insufficient balance - 402',
    // 'authorized but forbidden to access that resource - 403', 'resource you are looking for is not found - 404'
    // 'duplicate resource - 409', 'malformed body - 422'
    return [200, 201, 401, 402, 403, 404, 409, 422].includes(status);
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      /**
       * Require each request to have a token in the header
       * all requests are JSON encoded
       */
      config.headers["Content-Type"] = "application/json";

      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Use Element Plus Message for error handling
    // ElMessage.error(error.message || "An error occurred, contact your system administrator");
    return Promise.reject(error);
  }
);

export default axiosInstance;
